.carousel-status{
  display: none;
}

Header{
  position: fixed;
}

.test{
  :hover {
    color:red;
    top: -10px;
  }
}
div
{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.Mui-disabled.MuiPaginationItem-root
{
  padding: 0;
  margin: 0;
}
