/* Définissez votre animation */
@keyframes slide-from-top {
    0% { opacity: 0; transform: translateY(-100%)}
    100% { opacity: 1; transform: translateY(0)}
  }
  
  /* Appliquez l'animation à l'élément */
.slide-from-top-animation {
    animation: slide-from-top 0.5s ease;
}

/* Button Hamburger */
.bouton-hamburger {
    width: 26px;
    height: 33px;
    border: 0;
    background-image: url('bars-solid.svg');
    background-color: white;
  }
  
  
  